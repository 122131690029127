.contact-container {
  display: flex;
  flex-direction: row;
}
.contact-left {
  padding: 0 40px;
  flex-direction: column;
  justify-content: center;
}

.contact-left a {
  text-decoration: none;
  color: rgb(94, 61, 61);
}

.contact-left a button {
  border: none;
}

.contact-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-image {
  width: 90%;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }

  .contact-image {
    width: 70%;
  }
}
