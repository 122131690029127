.project-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
}

.project-card {
  margin: 10px;
  padding: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  box-shadow: 15px 15px 30px rgb(25, 25, 25), -15px -15px 30px rgb(60, 60, 60);
  position: relative;
}

.project-card img {
  width: 100%;
  border-radius: 20px;
}

.project-card a {
  text-decoration: none;
  color: black;
}

.github-button {
  position: absolute;
  bottom: 16px;
  left: 16px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 50px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
  transition: 0.35s ease all;
  overflow: hidden;
  max-width: 22px; /* icon size */
  background-color: white;
}

.demo-button {
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 50px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
  transition: 0.35s ease all;
  overflow: hidden;
  max-width: 22px;

  background-color: white;
}

.demo-button-label {
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0 8px;
  opacity: 0;
  transform: translateX(10px);
  transition: 0.25s ease all;
  color: black;
}

.github-button-label {
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0 8px;
  opacity: 0;
  transform: translateX(10px);
  transition: 0.25s ease all;
}

.project-card:hover {
  transform: scale(1.1);
  transition: all 1s ease-in-out;
  .demo-button {
    max-width: 100%;
  }

  .github-button-label {
    opacity: 1;
    transform: translateX(0);
    transition: 0.25s 0.1s ease-in opacity,
      0.15s 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275) transform;
  }

  .github-button {
    max-width: 100%;
    text-align: left;
  }

  .demo-button-label {
    opacity: 1;
    transform: translateX(0);
    transition: 0.25s 0.1s ease-in opacity,
      0.15s 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275) transform;
  }
}

.project-card h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.project-card p {
  font-size: 16px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .project-cards {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .project-cards {
    grid-template-columns: 1fr;
  }
}
