.main-container-about-me {
  padding: 20px;
  padding-top: 160px;
  max-width: 1200px;
  margin: 140px auto 20px;
}

.about-me {
  min-height: fit-content;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.about-me .about-me-text {
  text-align: justify;
}

.about-me-section {
  width: 45%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid rgb(107, 107, 107);
  border-radius: 30px;
  box-shadow: 15px 15px 30px rgb(25, 25, 25), -15px -15px 30px rgb(60, 60, 60);
}

.about-me-section h2 {
  border-bottom: 2px solid #636363;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.about-me-section ul {
  list-style-type: none;
  padding: 0;
}

@media (max-width: 768px) {
  .about-me {
    flex-direction: column;
  }
  .about-me-section {
    width: 90%;
  }
}
