/* src/ProfileCard.css */
.profile-card {
  padding: 40px;
  padding-top: 160px;
  max-width: 1200px;
  margin: 10px auto 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.profile-image {
  flex: 1 1;
  max-height: 300px;
  max-width: 300px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-shadow: 15px 15px 30px rgb(25, 25, 25), -15px -15px 30px rgb(94, 93, 93);
}

.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.buttons button {
  font-weight: 600;
  margin: 5px;
  padding: 1rem;
  cursor: pointer;
  border-radius: 2rem;
}

.social-icons {
  margin-top: 10px;
}

.social-icons a {
  font-size: 20px;
  margin: 0 10px;
  text-decoration: none;
  transition: color 0.3s ease;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .profile-card {
    flex-direction: column;
    padding: 40px;
  }

  .profile-image {
    width: 180px;
    height: 184px;
  }
}
