$lightcolor: rgb(221, 221, 221);
$dark-color: rgb(29, 29, 29);

body {
  font-family: "Courier New", monospace;
}

h1 {
  font-size: 42px;
}
h2 {
  font-size: 32px;
}
h3,
button {
  font-size: 25px;
}

.main-container {
  padding: 160px 40px 40px 40px;
  max-width: 1200px;
  margin: 140px auto 10px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 25px;
  }
  h3,
  button {
    font-size: 18px;
  }

  .main-container {
    padding: 30px;
    max-width: 1200px;
    margin: auto;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 16px;
  }
  h3,
  button {
    font-size: 14px;
  }
}
