.cards {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.card {
  flex: 1;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 15px 15px 30px rgb(25, 25, 25), -15px -15px 30px rgb(60, 60, 60);
}

.card h3 {
  margin-bottom: 15px;
  text-align: left;
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.item {
  flex: 1 1 calc(33.333% - 10px);
  padding: 10px;
  text-align: left;
  font-size: 22px;
}

.icon {
  cursor: pointer;
  height: 2rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .cards {
    flex-direction: column;
  }

  .icon {
    height: 1rem;
    gap: 0px;
  }

  .item {
    flex: 1 1 calc(33%);
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .item {
    font-size: 14px;
  }
}
